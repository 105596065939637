import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { toast } from 'sonner';
import type { DjangoMessage, ToastType } from './types';

const toastClass =
  '_w-[356px] _p-3 _rounded _font-sans _shadow-md _flex _items-start _gap-x-2 _border';
const toastTypeClass: Record<ToastType, string> = {
  info: '_bg-blue-10 _text-blue-100 _border-blue-20',
  success: '_bg-green-10 _text-green-100 _border-green-20',
  warning: '_bg-yellow-10 _text-yellow-100 _border-yellow-20',
  error: '_bg-red-10 _text-red-100 _border-red-20',
};

const iconClass = '_w-6 _h-6 _stroke-2';
const iconComponents: Record<ToastType, React.ReactNode> = {
  info: <InformationCircleIcon className={iconClass} aria-hidden="true" />,
  success: <CheckCircleIcon className={iconClass} aria-hidden="true" />,
  warning: <ExclamationCircleIcon className={iconClass} aria-hidden="true" />,
  error: <XCircleIcon className={iconClass} aria-hidden="true" />,
};

const toastDurations: Record<ToastType, number> = {
  // in milliseconds
  info: 5000,
  success: 5000,
  warning: Infinity,
  error: Infinity,
};

/**
 * Show a toast notification with the given content.
 * @param type The type of toast to show.
 * @param content The content of the toast, either a string or a React node.
 * @param containerClassName (optional) Class string to add to the toast container.
 * @param duration (optional) Override the duration of the toast, in milliseconds.
 */
export default function showToast({
  type,
  content,
  containerClassName = '',
  duration,
}: {
  type: ToastType;
  content: string | React.ReactNode;
  containerClassName?: string;
  duration?: number;
}) {
  toast.custom(
    (toastId) => (
      <>
        <div className="_p-[2px]">{iconComponents[type]}</div>
        <div className="_flex-1 _pt-[2px]">{content}</div>
        <button
          className="_opacity-50 hover:_opacity-100 _transition-opacity _p-1"
          onClick={() => toast.dismiss(toastId)}
        >
          <XMarkIcon className="_w-5 _h-5 _stroke-2" aria-hidden="true" />
        </button>
      </>
    ),
    {
      duration: duration ?? toastDurations[type],
      classNames: {
        toast: [
          toastClass,
          toastTypeClass[type],
          containerClassName,
          // Sonner normally sets opacity of non-front toast content to 0, but since we are using the
          // custom() method, the toasts are marked as unstyled, and the included CSS here does not apply:
          // https://github.com/emilkowalski/sonner/blob/main/src/styles.css#L293-L295
          '*:data-[sonner-toast]:data-[expanded=false]:data-[front=false]:_opacity-0',
        ].join(' '),
      },
    }
  );
}

export function showDjangoMessageAsToast(message: DjangoMessage) {
  let duration: number | undefined;
  // Parses "duration:5s" to 5000 milliseconds
  const durationMatch = message.tags.match(/duration:(\d+)s/);
  if (durationMatch) {
    duration = parseInt(durationMatch[1]) * 1000;
  }
  if (message.tags.includes('duration:infinite')) {
    duration = Infinity;
  }
  showToast({
    type: message.level,
    content: message.tags.includes('safe') ? (
      <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
    ) : (
      message.message
    ),
    containerClassName: message.tags,
    duration,
  });
}
